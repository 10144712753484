<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="userList-container common-padding" >
    <div class="title-main">
      <p><span class="normal">员工列表</span></p>
      <div>
        <el-button class="common-screen-btn common-noMarginLeft" type="primary" @click="invitePerson()">邀请员工</el-button>
        <el-button class="common-screen-btn" type="primary" @click="addDrawer()">新增员工</el-button>
        <div class="common-upload-container" style="margin-top: 0">
          <el-button class="common-screen-btn" :loading="this.$vuex.getters.btnLoading">上传员工表格</el-button>
          <input v-if="!this.$vuex.getters.btnLoading" class="common-upload-input" @input="(e) => {uploadUserXslx(e)}" type="file"/>
        </div>
        <el-button type="text" @click="() => download()">下载员工模板</el-button>
      </div>
    </div>
    <div class="common-Summary-container">
      <div class="Summary-item common-nofinger">
        <div class="item-title">注册用户总数</div>
        <div class="item-num common-nofinger">
          {{ headeData.totalNum || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">今日新增注册用户数</div>
        <div
          @click="
            () => {
              collect(
                'todayNum',
                '今日新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' }
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          class="item-num"
        >
          {{ headeData.todayNum || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">昨日新增注册用户数</div>
        <!-- { name: '所属公司', type: 'select', modelKey: 'companyId', selOpt: rolesCompany }, -->
        <div
          @click="
            () => {
              collect(
                'yesterdayNum',
                '昨日新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' }
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          class="item-num"
        >
          {{ headeData.yesterdayNew || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本周新增注册用户数</div>
        <div
          @click="
            () => {
              collect(
                'weekNum',
                '本周新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' }
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          class="item-num"
        >
          {{ headeData.weekNew || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本月新增注册用户数</div>
        <div
          @click="
            () => {
              collect(
                'monthNum',
                '本月新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' }
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          class="item-num"
        >
          {{ headeData.monthNum || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">在线客服数</div>
        <div
          @click="
            () => {
              collect(
                'serviceLoginNum',
                '在线客服数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' }
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              );
            }
          "
          class="item-num"
        >
          {{ headeData.serviceLoginNum || "0" }}
        </div>
      </div>
      <div class="Summary-item">
        <div class="item-title">用户调查统计</div>
        <div
          @click="
            () => {
              windowOpen();
            }
          "
          class="item-num"
        >
          {{ headeData.questionnaireNum || "0" }}
        </div>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>员工名称:</span>
        <el-input
          v-model="params.name"
          class="common-screen-input"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>账号ID:</span>
        <el-input
          v-model="params.account"
          class="common-screen-input"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>角色:</span>
        <el-select
          class="common-screen-input"
          v-model="params.roleId"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option
            v-for="item in selectRole"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属部门:</span>
        <a-cascader
          class="common-screen-input"
          v-model="params.userDepartId"
          :options="selectDepart"
          :fieldNames="{
            label: 'name',
            value: 'id',
            children: 'child'
          }"
          placeholder="请选择"
          change-on-select
          @change="commonFun"
        />
      </div>
      <div class="common-input-container line">
        <div class="common-input-container line">
          <span class="time">注册时间:</span>
          <common-date
            @commonGetDate="commonGetDate"
            ref="commonReset"
          ></common-date>
        </div>
        <div class="common-input-container">
          <el-button
            class="common-screen-btn"
            type="primary"
            @click="() => commonFun()"
            >查 询</el-button
          >
          <el-button class="common-screen-btn" @click="() => resetBtn()" plain
            >重 置</el-button
          >
        </div>
      </div>
    </div>
    <div class="table-main">
      <el-table class="common-table" :data="tableData">
        <el-table-column
          prop="nickName"
          label="用户名称"
          :formatter="tableColumn"
          width="160px"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="() => getPersDeatil(scope.row)"
              >{{ scope.row.nickName || "--" }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="account"
          label="账号ID"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="roleName"
          label="角色"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="userDepartName"
          label="所属部门"
          :formatter="tableColumn"
          width="120px"
        ></el-table-column>
        <el-table-column
          prop="companyName"
          label="公司名称"
          :formatter="tableColumn"
          width="120px"
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="birth_day"
          label="生日"
          :formatter="tableColumn"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <el-table-column
          prop="bank_card"
          label="银行卡号"
          :formatter="tableColumn"
          width="200px"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="注册时间"
          :formatter="tableColumn"
          width="160px"
        ></el-table-column>
        <el-table-column label="操作" width="170px" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="() => editPassword(scope.row.id)"
              >修改密码</el-button
            >
            &nbsp;&nbsp;&nbsp;
            <el-popconfirm
              placement="top"
              title="确定删除吗？"
              @confirm="() => quitDelete(scope.row)"
            >
              <el-button type="text" size="small" slot="reference"
                >离职并删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
     </div>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center;margin-bottom: 32px"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="修改密码"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              class="dialog-input"
              v-model="ruleForm.newPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button
          class="common-screen-btn"
          @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <user-add
      ref="addUser"
      :drawer="drawer"
      :rolesCompany="rolesCompany"
      :selectRole="selectRole"
      :tableRow="tableRow"
      :levelSelect="levelSelect"
      :selectDepart="selectDepart"
      :companyId="comId"
      @handleClose="handleClose"
      @addUserInfo="addUserInfo"
    ></user-add>
    <!-- 离职删除啊 -->
    <quit-delete
      ref="quitDialog"
      :quitVisible="quitVisible"
      @handleClose="handleClose"
      @Refresh="Refresh"
    ></quit-delete>
    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="userSumList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>

    <!-- 邀请注册弹窗 -->
    <el-dialog
      v-dialogDrag
      class="iptSign-dialog"
      title="邀请员工"
      :visible.sync="inviteVisible"
      width="30%"
      :before-close="invitehandleClose"
      append-to-body
    >
      <div style="text-align: center;">
        <span class="sign">注册链接</span>
        <div id="iptSign">
          <el-input v-model="signUpIpt" class="iptSign"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            () => {
              copy();
            }
          "
          >复制链接</el-button
        >
      </span>
    </el-dialog>

    <!-- 详情弹窗 -->
    <user-details
      :drawer="userDrawer"
      :selectRole="selectRole"
      :selectDepart="selectDepart"
      ref="companyDetail"
      :levelSelect="levelSelect"
      @handleClose="handleClose2"
    ></user-details>

    <!-- 指定人员  弹弹弹 -->
    <el-dialog
      v-dialogDrag
      title="删除提醒"
      :visible.sync="delVisible"
      width="30%"
      append-to-body>
      <div v-show="delList.length" style="margin-bottom: 20px;">当前人员正在参与工作事项中，选择替换人员进行删除人员</div>
      <div v-show="!(delList.length)" style="margin-bottom: 20px;">当前人员暂无参与工作事项，可以进行删除人员</div>
      <div v-for="(item,i) in delList" :key="i" class="work">
        <div class="name">{{item.workTypeName}}</div>
        <div>
          <el-button @click="seeDetails(item)" type="text">查看详情</el-button>
          <el-button @click="replace(item)" type="text">替换人员</el-button>
          <i class="el-icon-warning-outline orange"></i>
          <!-- <i class="el-icon-circle-check green"></i> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">取 消</el-button>
        <el-button type="primary" @click="delRequest">确 定</el-button>
      </span>
    </el-dialog>
    
    <!-- 替换人员  弹弹弹 -->
    <el-dialog
      v-dialogDrag
      title="替换人员"
      :visible.sync="replaceVisible"
      width="30%"
      append-to-body
      :before-close="
        () => {
          beforeClose();
        }
      "
      :close-on-click-modal="false">
      <div class="common-chooseItem-container">
        <div
          v-for="(item, index) in checkedList"
          :key="index"
          class="common-choose-item"
        >
          <span>{{ item.name }}</span
          ><i
            @click="
              () => {
                deletePerson(item);
              }
            "
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <br /><br />
      <div class="common-person-container">
        <div class="person-title">
          <div class="left-text">组织成员</div>
          <div class="right-seach">
            <el-input
              class="seach-input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="(val) => seachFun(val)"
              v-model="seachInfo"
            ></el-input>
          </div>
        </div>
        <div class="person-content">
          <div class="content-left">
            <el-tree
              :data="roleData"
              :props="defaultProps"
              @node-click="
                (val) => {
                  departTreeFun(val);
                }
              "
            ></el-tree>
          </div>
          <div class="content-right">
            <el-radio-group v-model="radio">
              <el-radio 
                @change="(val) => {changePerson(val, item);}"
                :label="item.id" 
                v-for="(item, index) in personData" 
                :key="index"
                style="display: block;"
                >{{item.nickName}}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              beforeClose();
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              saveRole();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>
<script>
import UserAdd from "./children/userListAdd.vue";
import { Config } from "../../utils/index.js";
import { tableColumn } from "../../utils/index.js";
import {
  updatePassword,
  addUser,
  updateUserInfo,
  deleteUserItem,
  uploadUserXslx,
  userSumList,
  selectWorkOrderList,
  updateApproveId
} from "../../service/manage.js";
import { serLevelSelect } from "../../service/service.js"
import { commonAllRole, commonDepartAll,personList } from "../../service/common.js";
import QuitDelete from "./children/quitDelete.vue";
import CommonDate from "../../components/common/commonDate.vue";
import CommonSumDialog from "../../components/common/commonSumDialog.vue";
import { list } from "../../service/login.js";
import userDetails from "./children/userDetails.vue";
import { Cascader } from "ant-design-vue";
import { mapState } from "vuex";

export default {
  components: {
    QuitDelete,
    UserAdd,
    CommonSumDialog,
    CommonDate,
    userDetails,
    aCascader: Cascader
  },
  data() {
    return {
      tableColumn,
      loading: false,
      // BreadcrumbData: [
      //   { title: "首页", isLink: true, url: "/index" },
      //   { title: "组织", isLink: false },
      //   { title: "员工列表", isLink: false }
      // ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10
        // companyId: -1,
        // roleId: null,
        // userDepartId: null,
      },
      centerDialogVisible: false, // 修改密码员工弹窗
      quitVisible: false, // 员工离职弹窗
      ruleForm: {
        // 修改密码
        newPassword: "",
        id: "" // 用户id
      },
      rules: {
        newPassword: [
          { required: true, message: "请填写密码", trigger: "change" }
        ]
      },
      drawer: false, // 新增员工弹窗
      userDrawer: false, // 员工详情弹窗
      inviteVisible: false, //邀请注册
      tableRow: "",
      selectRole: [], // 角色下拉
      selectDepart: [], // 部门下拉
      rolesCompany: this.$sto
        .get(Config.constants.userInfo)
        .roles_company.filter((item) => {
          return item.id != 0;
        }), // 所属公司下拉
      // rolesCompany: this.$sto.get(Config.constants.userInfo),
      tableData: [],
      headeData: {
        totalNum: 0,
        todayNum: 0,
        yesterdayNew: 0,
        weekNew: 0,
        monthNum: 0,
        serviceLoginNum: 0,
        questionnaireNum: 0
      },

      // 汇总弹窗所需数据
      commonSumVisible: false,
      userSumList, // 列表接口方法
      signUpIpt: "",
      levelSelect: [], // 客服等级下拉
      delVisible: false, //删除提醒弹窗
      delList: [], //删除提醒弹窗---内容
      replaceVisible: false, //指定人员弹窗--替换人员
      seachInfo: "",
      defaultProps: {
        children: "child",
        label: "name"
      },
      radio: "",//人员id
      roleData: [],// 角色列表
      personData: [], //角色下人员
      checkedList: [],
      updateParams: {
        approveType: 2
      },
      delRow: {}, //删除一行的数据
    };
  },
  created() {
    this.getComId()

    // this.commonAllRole();
    // this.getTableData();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.commonDepartAll(this.params.companyId);
        this.commonAllRole();
        this.getTableData();
        this.serLevelSelect()
      },400)
    },
    async serLevelSelect() { // 客服列表等级下拉
      let { data } = await serLevelSelect({ companyId: this.comId })
      this.levelSelect = data
    },
    // 数据汇总弹窗-start
    sumVisibleClose() {
      this.commonSumVisible = false;
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end

    async quitDelete(row) {
      this.delRow = row
      this.updateParams.leaveAdminId = this.delRow.id
      if(row.isRelevanceWork){
        const { data } = await selectWorkOrderList({approveId: row.id})
        this.delList = data.list
        return this.delVisible = true
      }
      this.$refs["quitDialog"].getParams(row);
      this.quitVisible = true;
    },
    async uploadUserXslx(e) {
      // 上传员工表格
      this.$vuex.commit("btnLoading", true);
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持、xls、xlsx格式文件");
        e.target.value = "";
        return;
      }
      let res = await uploadUserXslx({ excelFile: file });
      e.target.value = "";
      if (res.code == 200) {
        this.getTableData();
        this.$message.success("上传成功");
      }
    },
    download() {
      // 下载员工模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E5%91%98%E5%B7%A5%E8%A1%A8.xlsx";
    },
    async deleteUserItem(id) {
      // 删除指定用户
      await deleteUserItem({}, id);
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.$message.success("删除成功");
      this.getTableData();
    },
    commonGetDate(startDate, endDate) {
      this.params.startDate = startDate;
      this.params.endDate = endDate;
      this.getTableData();
    },
    companyFun(id) {
      //选择公司筛选
      this.params.userDepartId = [];
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getTableData();
      this.commonDepartAll(id);
      this.commonAllRole(id);
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getTableData();
    },
    cascaderFun() {
      // 选择部门
      let params = { ...this.params };
      if (!params.companyId) {
        this.$message.error("请先选择所属公司");
        this.params.userDepartId = [];
        // delete params.userDepartId;
        // this.params = params;
      }
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    async commonAllRole() {
      // 角色下拉
      let params = { ...this.params };
      let resData = (await commonAllRole({ companyId: params.companyId })).data;
      this.selectRole = resData;
    },
    async addUserInfo(ruleFormAdd) {
      // 新增用户--子组件掉的方法
      let ruleFormParams = { ...ruleFormAdd };
      let tableId = this.tableRow.id;
      if (this.tableRow) {
        // delete ruleFormParams.passWord
        // delete ruleFormParams.oldPassword
        let res = await updateUserInfo(ruleFormParams, tableId);
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.getTableData();
          this.drawer = false;
        }
      } else {
        let res = await addUser(ruleFormParams);
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.params.pageNum = 1;
          this.params.pageSize = 10;
          this.getTableData();
          this.drawer = false;
        }
      }
    },
    // 邀请注册
    invitePerson() {
      let nickName = this.$sto.get(Config.constants.userInfo).nick_name;
      this.signUpIpt = `https://dev.fwwb.vip/pers-continue?user=${nickName}&comName=${this.rolesCompany[0].name}&comId=${this.params.companyId}`;
      this.inviteVisible = true;
    },
    resetBtn() {
      this.$refs.commonReset.resetFun();
      this.params = {
        pageNum: 1,
        pageSize: 10,
        name: "",
        companyId: this.comId
      };
      
      this.commonDepartAll(this.comId);

      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getTableData();
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      let params = { ...this.params };
      if (params.userDepartId && params.userDepartId.length) {
        params.userDepartId =
          params.userDepartId[params.userDepartId.length - 1];
      }
      if (typeof params.userDepartId == "object") {
        delete params.userDepartId;
      }
      if (!params.roleId) {
        delete params.roleId;
      }

      let resData = (await list(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.headeData = {
        todayNum: resData.today,
        totalNum: resData.total,
        monthNum: resData.month,
        yesterdayNew: resData.yesterday,
        weekNew: resData.week,
        serviceLoginNum: resData.serviceLoginNum,
        questionnaireNum: resData.questionnaireNum
      };
      this.tableData = resData.records;
      this.loading = false;
    },
    // 详情
    async getPersDeatil(row) {
      this.$refs.companyDetail.getDataInfo(row);
      this.userDrawer = true;
    },
    editPassword(e) {
      // 修改密码弹窗

      this.ruleForm.id = e;
      this.centerDialogVisible = true;
    },
    submitForm(formName) {
      // 修改密码确认
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword(this.ruleForm, this.ruleForm.id);
          this.centerDialogVisible = false;
          this.getTableData();
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.drawer = false;
      this.quitVisible = false;
      // 关闭新增员工弹窗
      // done();
    },
    handleClose2() {
      this.userDrawer = false;
      this.getTableData();
    },
    invitehandleClose() {
      this.inviteVisible = false;
    },
    // 复制按钮
    copy() {
      var val = document.getElementById("iptSign"); //此处为需要复制文本的包裹元素
      window.getSelection().selectAllChildren(val);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.inviteVisible = false;
      this.$message.success("复制成功！");
    },
    Refresh() {
      this.getTableData();
      this.quitVisible = false;
    },
    addDrawer(row) {
      //新增/编辑员工
      this.drawer = true;
      if (row) {
        this.tableRow = row;
        this.$refs.addUser.getUserInfo(row);
      } else {
        this.tableRow = "";
        this.$refs.addUser.clearForm();
      }
    },
    windowOpen() {
      window.open(`/manage/user-echarts?comId=${this.params.companyId}`);
    },
    delRequest(){ //删除弹窗-----确定按钮
      if(this.delList.length){
        return this.$message.error("请先选择替换人员")
      }
      this.$refs["quitDialog"].getParams(this.delRow);
      this.quitVisible = true;
      this.delVisible = false
    },
    seeDetails(item) { // 指定人员弹窗--查看详情
      if(item.workTypeId == '1'){ //任务
        this.$router.push({path: '/work-order/edit',query:{id: item.id}})
      }else if(item.workTypeId == '2'){ //审批
        this.$router.push({path: '/work-order/new-examine',query:{id: item.id,name: 'edit'}})
      }else if(item.workTypeId == '3') { //应用场景
        this.$router.push({path: '/work-order/scene',query:{id: item.id,name: 'edit'}})
      }
    },
    replace(item) { //// 指定人员弹窗--替换人员
      this.updateParams.leaveAdminId = this.delRow.id
      this.updateParams.workOrderId = item.id
      this.replaceVisible = true
      this.commonAllRole2()
    },
    async commonAllRole2() {
      this.checkedList = [];
      // 角色列表
      let { data } = await commonAllRole({ companyId: -1 });
      this.roleData = data;
    },
    departTreeFun(val) {
      this.seachInfo = ""
      //部门选择
      let params = {
        companyId: this.comId,
        roleId: val.id,
        pageNum: 1,
        pageSize: 999999
      };
      this.personList(params);
    },
    async personList(params) {
      // 获取人员
      let { data } = await personList(params);
      this.personData = data.records || [];
    },
    seachFun(val) {
      // 搜索人员
      let params = {
        companyId: this.comId,
        name: val,
        pageNum: 1,
        pageSize: 999999
      };
      this.personList(params);
    },
    changePerson(val, row){
      // 勾选
      let checkedList = [...this.checkedList];
      if (val) {
        checkedList = [{id: val,name: row.nickName}]
      }
      this.checkedList = checkedList;
    },
    deletePerson(item) {//删除人员
      this.checkedList = this.checkedList.filter((item2) => {
        return item2.id != item.id;
      });
      this.radio = ""
    },
    beforeClose() {
      this.replaceVisible = false
      this.checkedList = [];
      this.roleData = []
      this.personData = []
      this.updateParams = {
        approveType: 2
      }
      this.radio = ""
    },
    // 替换人员确认
    saveRole(){
      this.$confirm('确定替换吗？', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'common-deleteMessage',
            type: 'warning'
        }).then(async() => {
          let params = {...this.updateParams}
          params.approveId = this.checkedList[0].id
          const res = await updateApproveId(params)
          if(res.code == 200){
            this.$message.success("替换成功")
            // 刷新删除提醒弹窗数据
            const { data } = await selectWorkOrderList({approveId: this.updateParams.leaveAdminId})
            this.delList = data.list
          }
          this.beforeClose();
        }).catch(() => {
        this.$message({
            type: 'info',
             message: '已取消'
        });
        });
    },
  }
};
</script>
<style lang="less" scoped>
.iptSign-dialog {
  /deep/.el-dialog__footer {
  text-align: center !important;
}
}
/deep/.el-dialog__footer {
  text-align: right !important;
}
.userList-container {
  text-align: left;
  .common-screen-container {
    padding: 16px 0 0 32px;
    margin: 0;
  }
  .common-table {
    margin: 0;
  }
  .table-main {
    padding: 0 32px 32px;
  }
  .title-main {
    display: flex;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
    height: 72px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    p {
      font-size: 20px;
      color: #666;
      span {
        font-size: 20px;
        color: #666;
        cursor: pointer;
      }
      .normal {
        font-size: 24px;
        color: #101010;
        cursor: auto;
      }
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
  .line {
    display: flex;
    align-items: center;
    .time {
      margin-right: 16px;
    }
  }
  .new:hover {
    cursor: pointer;
    background: #fff;
    border: 0.00521rem solid #dcdfe6;
    color: #606266;
  }
}
.dialog-input {
  width: 320px !important;
}
.sign {
  display: block;
  font-size: 20px;
  color: #333;
  margin-bottom: 24px;
}
/deep/.iptSign {
  height: 48px !important;
  .el-input__inner {
    height: 48px !important;
  }
}
/deep/.el-dialog__footer {
  text-align: center;
}
.work {
  box-sizing: border-box;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background-color: #f6f7f6;
  border-radius: 4px;
  margin-bottom: 16px;
  .name {
    font-size: 14px;
    color: #333;
  }
  .orange {
    color: #F09009;
    margin-left: 4px;
  }
  .green {
    color: #52C419;
    margin-left: 4px;
  }
}
</style>
